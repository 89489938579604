.snackbar {
  color: #fff;
  text-align: center;
  padding: 16px;
  position: fixed;
  z-index: 2000;
  width: 300px;
  right: 16px;
  bottom: 16px;
}

.snackbarHidden {
  visibility: hidden;
}

.snackbarVisible {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.snackbarSuccess {
  background-color: #27ae60;
}

.snackbarError {
  background-color: var(--red);
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}
