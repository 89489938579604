.root {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
}

.logo {
    max-width: 25vw;
    margin-bottom: var(--l-spacing);

    @media (--phone) {
        max-width: 50vw;
    }
}

.loader {
    color: white !important;
}
