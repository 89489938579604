@custom-media --phone (max-width: 1024px);

@custom-media --desktop (min-width: 1025px);

:root {
  --xxs-spacing: 2px;
  --xs-spacing: 4px;
  --s-spacing: 8px;
  --m-spacing: 12px;
  --l-spacing: 16px;
  --xl-spacing: 20px;
  --xxl-spacing: 24px;
  --xxxl-spacing: 28px;
  --xxxxl-spacing: 32px;
  --xxxxxl-spacing: 36px;
  --xxxxxxl-spacing: 40px;

  --bold: 700;
  --semi-bold: 600;

  --primary-color: #ee2f58;
  --seconday-color: #343a40;
  --white: white;
  --red: red;
  --dim-gray: #696969;
  --grey: #f5f5f5;
  --blue:#508caf;

  --border-radius: 8px;

  --h1-font-size: 24px;
  --h2-font-size: 20px;
  --h3-font-size: 16px;
  --m-font-size: 12px;
  --s-font-size: 10px;
  --xs-font-size: 8px;

  --body-line-height: 1.5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
