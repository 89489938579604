@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~react-table/react-table.css";

html, body {
  font-family: "Roboto", serif;
  font-family: "Open Sans";
  font-size: var(--h3-font-size);
  line-height: var(--body-line-height);
}

.bold {
  font-weight: var(--bold) !important;
}

.sentenceCase {
  text-transform: capitalize;
}

.textCenter {
  text-align: center;
}

 /* React Table overide css */
.ReactTable {
  border-radius: 6px;
  height: calc(100vh - 117px);
  overflow: auto;
  background: var(--white);
  font-size: 14px;
}

.ReactTable.customHeight {
  height: calc(100vh - 150px) !important;
}

.ReactTable .rt-thead {
  font-weight: var(--semi-bold);
}

.ReactTable .rt-thead .rt-tr{
  text-align: left;
}

/* Material override css */
.MuiCardActions-root .MuiButton-contained {
  min-width: 150px;
}

.MuiInputBase-root .Mui-disabled, .MuiInputBase-root.Mui-disabled{
  color: var(--seconday-color) !important;
  background: var(--grey);
  background-color: var(--grey) !important;
  cursor: not-allowed;
}

.MuiTextField-root .Mui-disabled {
  color: #000000 !important;
}
